<template>
  <div class="content">
    <div class="tbl_wrap">
      <div class="tbl_head float_area">
        <div class="float-left">
          <div class="tbl_result">
            <h5>
              관리자 목록<span class="tit_desc"
                >검색결과 (총 {{ state.totalItems }}명)</span
              >
            </h5>
          </div>
        </div>
        <div class="float-right align-items-center">
          <div class="tbl_btnset">
            <form
              class="search-group"
              @submit.prevent="
                getItems({
                  role: 'ADMIN',
                  keyword: state.searchKeyword,
                  page: 1,
                  size: 15,
                })
              "
            >
              <input
                type="text"
                class="form-control"
                placeholder="이메일 키워드"
                v-model="state.searchKeyword"
              />
            </form>
            <!-- <router-link :to="{ name: 'CreateAdminItem' }"> -->
            <button
              type="button"
              class="btn btn-primary"
              @click="openModal('create')"
            >
              <i class="mr-5px"
                ><font-awesome-icon
                  class="gnb_icon"
                  type="fas"
                  icon="fa-plus"
                ></font-awesome-icon> </i
              >등록
            </button>
            <!-- </router-link> -->
            <button
              type="button"
              class="btn btn-secondary"
              :disabled="!state.checkedItems.length"
              @click="deleteItems"
            >
              선택 삭제
            </button>
          </div>
        </div>
      </div>
      <base-table
        :useNo="true"
        :fields="state.fields"
        :items="state.items"
        :is-edit="false"
        :isCheckbox="true"
        :loading="state.contentLoading"
        :totalItems="state.totalItems"
        :pageSize="state.pageSize"
        :currentPage="state.currentPage"
        @onChecked="onChecked"
        @onTableClick="onTableClick"
      >
      </base-table>
      <nav class="mt-20px">
        <base-pagination
          v-if="state.totalPages > 1"
          :total="state.totalItems"
          :loading="state.contentLoading"
          :currentPage="state.currentPage"
          :pageCount="state.totalPages"
          :perPage="state.pageSize"
          @change="
            (page) =>
              getItems({
                keyword: state.searchKeyword,
                page: page,
                size: 15,
              })
          "
        />
      </nav>
      <Modal
        :target="state.target"
        v-if="getters.getModalStatus(state.target)"
        @clickCreateBtn="clickCreateBtn"
        @clickUpdateSaveBtn="clickUpdateSaveBtn"
        @clickDeleteBtn="clickDeleteBtn"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import memberService from "@/services/member.service";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import Modal from "@/components/core/Modal.vue";

// # hooks
const router = useRouter();
const store = useStore();
const getters = computed(() => store.getters);

// # state
const state = reactive({
  target: "member",
  items: [],
  checkedItems: [],
  searchKeyword: "",
  showModal: false,
  contentLoading: true,
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 15,
  userId: 0,
  fields: [
    // table thead fields
    {
      key: "email",
      label: "이메일",
      useClick: true,
      width: "70%",
    },
    {
      key: "createDate",
      label: "가입날짜",
      useClick: true,
      width: "20%",
    },
  ],
});

// # method
// 다건 조회
const getItems = async (params = { role: "ADMIN", page: 1, size: 15 }) => {
  const res = await memberService.findAll(params);
  state.items = res.data.content;
  state.currentPage = params.page;
  state.totalItems = res.data.totalElements;
  state.totalPages = res.data.totalPages;
  state.contentLoading = false;
};
// 다건 삭제
const deleteItems = async () => {
  await memberService.deleteAll({ idList: state.checkedItems.join(",") });
  router.go();
};

// 체크
const onChecked = (items) => {
  state.checkedItems = items.map((item) => item.id);
};

// // 테이블 클릭 이벤트 받기
// const onTableClick = (params) => {
//   const id = params.item.id;
//   router.push({
//     name: "ReadAdminItem",
//     params: { id: id },
//   });
// };
// 모달창 생성
const openModal = (type) => {
  store.commit("setModalMode", { target: state.target, mode: type });
  store.commit("setModalStatus", { target: state.target, status: true });
};

// 행 클릭시 조회모달 생성 ===========
const onTableClick = (checkedItem) => {
  const params = checkedItem.item.id;
  memberService.findOne(params).then(
    (response) => {
      if (response.status === 403) {
        if (!alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요")) {
          store.commit("setModalStatus", {
            target: state.target,
            status: false,
          });
          store.commit("LOGOUT");
          router.push({
            name: "Login",
          });
        }
      }

      for (var key in response.data) {
        store.commit("setModalItem", {
          target: state.target,
          key: key,
          value: response.data[key],
        });
      }
      openModal("read");
    },
    (error) => {
      console.error(error.data);
    }
  );
};

// 모달에서 등록실행
const clickCreateBtn = () => {
  const params = {};
  for (const key in getters.value.getModalItem(state.target)) {
    params[key] = getters.value.getModalItem(state.target)[key].value;
  }

  memberService.create(params).then((response) => {
    if (response.status === 403) {
      alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요");
      // TODO 데이터 초기화
      store.commit("setModalStatus", { target: state.target, status: false });
      store.commit("LOGOUT");
      router.push({
        name: "Login",
      });
    } else if (response.status === 200) {
      alert("회원등록이 완료되었습니다.");
      // TODO 데이터 초기화
      store.commit("setModalMode", { target: state.target, mode: "" });
      store.commit("setModalStatus", { target: state.target, status: false });
      router.go();
    } else {
      alert("에러발생");
    }
  });
};

// 모달에서 수정실행
const clickUpdateSaveBtn = () => {
  const params = {};
  for (const key in getters.value.getModalItem(state.target)) {
    params[key] = getters.value.getModalItem(state.target)[key].value;
  }
  memberService.update(params.id, params).then((response) => {
    if (response.status === 403) {
      alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요");
      // TODO 데이터 초기화
      store.commit("setModalStatus", { target: state.target, status: false });
      store.commit("LOGOUT");
      router.push({
        name: "Login",
      });
    } else if (response.status === 200) {
      alert("회원수정이 완료되었습니다.");
      // TODO 데이터 초기화
      store.commit("setModalMode", { target: state.target, mode: "read" });
    } else {
      alert("에러발생");
    }
  });
};

// 모달에서 수정실행
const clickDeleteBtn = () => {
  const confirmText =
    "회원정보는 복구할 수 없습니다. \n정말 삭제하시겠습니까??";
  if (confirm(confirmText)) {
    memberService
      .delete(getters.value.getModalItem(state.target)["id"].value)
      .then((response) => {
        if (response.status === 403) {
          alert("로그인 지속시간이 지났습니다. 다시 로그인해주세요");
          // TODO 데이터 초기화
          store.commit("setModalStatus", {
            target: state.target,
            status: false,
          });
          store.commit("LOGOUT");
          router.push({
            name: "Login",
          });
        } else if (response.status === 200) {
          alert("회원삭제가 완료되었습니다.");
          // TODO 데이터 초기화
          store.commit("setModalMode", { target: state.target, mode: "" });
          store.commit("setModalStatus", {
            target: state.target,
            status: false,
          });
          router.go();
        } else {
          alert("에러발생");
        }
      });
  }
};

// # lifecycle
onMounted(() => {
  getItems();
});
</script>

<style lang="scss" scoped>
.posts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .post {
    display: flex;
    gap: 5px;
  }
  a {
    color: blue;
  }
}
.search-group {
  display: flex;
  gap: 10px;
}
.filter-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.btn-group {
  display: flex;
  gap: 10px;
  * {
    display: inline-block;
    padding: 5px 10px;
    background-color: #ddda;
    border-radius: 5px;
    color: #666;
    &:hover {
      background-color: #ddd;
    }
  }
}
.tbl_btnset {
  font-size: unset;
  display: flex;
  .search-group {
    button {
      flex-basis: 50px;
    }
  }
}
</style>
